import {DataSet} from "../types";
import {VEC_T_MAX_LEN} from "./constants";
import SerialTrait from "./serial_trait";

const BN = require("bn.js");

class DataSetsSerial implements SerialTrait {
    data: Array<DataSet>;

    constructor(data: Array<DataSet>) {
        this.data = data;
    }

    serial = (): Buffer => {
        if (this.data && this.data.length > VEC_T_MAX_LEN) {
            throw new Error("INVALID_FORMAT");
        }

        /**
* -  serial = (): Buffer => {
-    if (this.data && this.data.length > VEC_T_MAX_LEN) {
-      throw new Error("INVALID_FORMAT");
-    }
-    const len = this.data.length;
-    const lenBuf = new BN(len).toArrayLike(Buffer, "be", 4);
-    const bufArr: Array<Buffer> = [lenBuf];
-    for (let d of this.data) {
-      bufArr.push(
-        Buffer.concat([
-          Buffer.from(d.name, "hex"),
-          new BN(Buffer.from(d.data, "hex").length).toArrayLike(
-            Buffer,
-            "be",
-            4
-          ),
-          Buffer.from(d.data, "hex"),
-        ])
-      );
-    }
-    return Buffer.concat(bufArr);
-  };
         -}
         */
        const len = this.data.length;
        const lenBuf = new BN(len).toArrayLike(Buffer, "be", 4);
        const bufArr: Array<Buffer> = [lenBuf];
        for (let d of this.data) {
            const nameBuf = Buffer.from(d.name, "hex");
            const dataLenBuf = new BN(Buffer.from(d.data, "hex").length).toArrayLike(Buffer, "be", 4)
            const dataBuf = Buffer.from(d.data, "hex")

            const interBuf:Array<Buffer> = [];
            interBuf.push(nameBuf);
            interBuf.push(dataLenBuf);
            interBuf.push(dataBuf);

            if(d.old){
                const oldStation = new BN(1).toArrayLike(Buffer, "be", 1)
                const oldLenBuf = new BN(Buffer.from(d.old, "hex").length).toArrayLike(
                    Buffer,
                    "be",
                    4
                )
                const oldBuf = Buffer.from(d.old, "hex")

                interBuf.push(oldStation);
                interBuf.push(oldLenBuf);
                interBuf.push(oldBuf);
            }else{
                const oldStation = new BN(0).toArrayLike(Buffer, "be", 1)
                interBuf.push(oldStation);
            }
            bufArr.push(Buffer.concat(interBuf));
        }
        return Buffer.concat(bufArr);
    };
}

export default DataSetsSerial;
